/* eslint-disable import/prefer-default-export */
/* eslint-disable no-alert */
import { ReactDOM } from 'react';
import { loadableReady } from '@loadable/component';

import './src/styles/typography.css';
import './src/styles/global.css';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This application has been updated. ' +
        'Reload to display the latest version?'
  );
  if (answer === true) {
    window.location.reload();
  }
};

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};
